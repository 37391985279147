// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-activity-js": () => import("./../src/pages/Activity.js" /* webpackChunkName: "component---src-pages-activity-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-analytics-js": () => import("./../src/pages/Service/Analytics.js" /* webpackChunkName: "component---src-pages-service-analytics-js" */),
  "component---src-pages-service-back-development-js": () => import("./../src/pages/Service/BackDevelopment.js" /* webpackChunkName: "component---src-pages-service-back-development-js" */),
  "component---src-pages-service-design-js": () => import("./../src/pages/Service/Design.js" /* webpackChunkName: "component---src-pages-service-design-js" */),
  "component---src-pages-service-testing-js": () => import("./../src/pages/Service/Testing.js" /* webpackChunkName: "component---src-pages-service-testing-js" */),
  "component---src-pages-service-web-development-js": () => import("./../src/pages/Service/WebDevelopment.js" /* webpackChunkName: "component---src-pages-service-web-development-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

